import React, { Suspense, lazy } from "react";
import Header from "../components/HomePage/Header";
import Features from "../components/HomePage/Features/Features";
import Social from "../components/Common/Social";
import Chatbot from "../components/Common/Chatbot";
import Loader from "../components/Common/Loader";
import { Helmet } from "react-helmet-async";

const WhyUs = lazy(() => import("../components/HomePage/WhyUs"));
const Stats = lazy(() => import("../components/HomePage/Stats"));
const Slides = lazy(() => import("../components/HomePage/Slides/Slides"));
const Customers = lazy(() =>
  import("../components/HomePage/Customers/Customers")
);
const Footer = lazy(() => import("../components/Common/Footer"));

export default function Home() {
  return (
    <div
      style={{
        fontFamily: '"Montserrat", sans-serif',
        backgroundColor: "#eee",
      }}
    >
      <Helmet>
        <meta
          name="title"
          content="SmarTech Advisory — Smart technology solutions for Consultancy, Energy, Infrastructure & IT"
        />
        <meta
          name="description"
          content="SmarTech Advisory — your digital transformation partner in Energy and technology solutions. Driving Sustainable energy systems and Advanced technology integration."
        />
        <title>
          Leading Renewable Energy & Smart Technology Solutions – SmarTech
          Advisory
        </title>
      </Helmet>
      <Header />
      <Features />
      <Suspense fallback={<Loader />}>
        <WhyUs />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Stats />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Slides />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Customers />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Footer />
      </Suspense>
      <Social />
      <Chatbot />
    </div>
  );
}
