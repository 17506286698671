export const images = {
  techBanner: require("./techBanner.png"),
  techBanner2: require("./techBanner2.png"),
  homeLeftCard: require("./homeCardLeft.png"),
  homeInnovate: require("./homeInnovation.png"),
  homeInspire: require("./homeInspire.png"),
  homeCollaborate: require("./homeCollaborate.png"),
  homeAchieve: require("./homeAchieve.png"),
  homeStatsImg: require("./homeStatsImg.png"),
  homeSlideAbout: require("./homeSlideAbout.jpg"),
  homeSlideEner: require("./homeSlideEner.jpg"),
  homeSlideConsult: require("./homeSlideConsult.jpg"),
  homeSlideSolar: require("./homeSlideSolar.jpg"),
  homeSlideTech: require("./homeSlideTech.jpg"),

  // menu: require("./menu.png"),
  commodity: require("./Commodity.svg"),
  // technology: require("./Technology.svg"),
  light: require("./Light.svg"),
  lightBg: require("./lightsBg.webp"),
  energy: require("./Energy.svg"),
  agni: require("./agni.png"),
  eco: require("./eco.png"),
  agarwal: require("./agarwal.png"),
  aia: require("./aia.png"),
  alternativeEnergy: require("./alternativeEnergy.jpg"),
  anand: require("./anand.png"),
  annapurna: require("./annapurna.png"),
  ansal: require("./ansal.png"),
  checkbox: require("./checkbox.svg"),
  conti: require("./conti.png"),
  desktopLogo: require("./desktopLogo.png"),
  fortis: require("./fortis.png"),
  gm: require("./gm.png"),
  gupta: require("./gupta.png"),
  h: require("./h.png"),
  heidelberg: require("./heidelberg.png"),
  hya: require("./hya.png"),
  Image2: require("./Image2.png"),
  img1: require("./img1.png"),
  img2: require("./img2.png"),
  // img3: require("./img3.jpg"),
  img4: require("./img4.png"),
  img5: require("./img5.png"),
  jai: require("./jai.png"),
  jw: require("./jw.png"),
  k: require("./k.png"),
  kair: require("./kair.png"),
  max: require("./max.png"),
  meghmanii: require("./meghmanii.png"),
  mittal: require("./mittal.png"),
  oreva: require("./oreva.png"),
  pacific: require("./pacific.png"),
  paras2: require("./paras2.png"),
  partner: require("./partner.png"),
  ramnikk: require("./ramnikk.png"),
  raymond: require("./raymond.png"),
  rishi: require("./rishi.png"),
  soktas: require("./soktas.png"),
  sunEnergyConcept: require("./sunEnergyConcept.jpg"),
  super1: require("./super1.png"),
  thomson: require("./thomson.png"),
  unnamed: require("./unnamed.png"),
  //about
  aboutBanner: require("./aboutBanner.png"),
  aboutImg1: require("./aboutImg1.png"),
  aboutImg2: require("./aboutImg2.png"),
  aboutImg3: require("./aboutImg3.png"),
  aboutImg4: require("./aboutImg4.png"),
  aboutImg5: require("./aboutImg5.png"),
  aboutImg6: require("./aboutImg6.png"),
  play: require("./play.png"),
  profile: require("./profile.png"),
  techImg1: require("./techImg1.png"),
  solarPlantBg: require("./solarPlantBg.jpg"),
  solarEnergy: require("./solarEnergy.png"),
  solar: require("./solar.jpg"),
  newSolarBack: require("./newSolarBack.jpg"),
  consultancyBanner: require("./consultancyBanner.jpg"),
  consultancy: require("./consultancy.jpg"),
  metering: require("./metering.png"),
  efficiency: require("./efficiency.png"),
  windTurbine: require("./windTurbine.png"),
  realTime: require("./realTime.png"),
  profit: require("./profit.png"),
  android: require("./android.png"),
  report: require("./report.png"),
  error: require("./error.png"),
  meterImg2: require("./meterImg2.jpg"),
  calculator: require("./calculator.jpg"),
  energyBanner: require("./energyBanner.png"),
  manpoer: require("./manpoer.jpg"),
  manpowerImg4: require("./manpowerImg4.jpg"),
  intro: require("./intro.jpg"),
  manpoweConsulting: require("./manpowerConsulting.jpg"),
  consultancyBanner1: require("./consultancyBanner1.png"),
  contactBg: require("./contactBg.png"),
  mediaBg: require("./mediaBg.png"),
  careerSec: require("./careerSec.png"),
  consultImg: require("./consultImg.png"),
  techSecBanner: require("./techSecBanner.png"),
  finalVideo: require("./FinalVideo.mp4"),
  //metering
  group126: require("./Group126.png"),
  group127: require("./Group127.png"),
  group128: require("./Group128.png"),
  group129: require("./Group129.png"),
  group130: require("./Group130.png"),
  group131: require("./Group131.png"),
  group132: require("./Group132.png"),
  //service-it
  tech: require("./technology.svg"),
  ai: require("./ai.jpg"),
  blockchain: require("./blockchain.jpg"),
  cmsWebsite: require("./cmsWebsite.jpg"),
  dataAnalysis: require("./dataAnalysis.jpg"),
  erpSol: require("./erpSolutions.jpg"),
  gisApp: require("./gis-applicaton.jpg"),
  mobileApp: require("./mobile-app.jpg"),
  ml: require("./ml.jpg"),
  evMobileApp: require("./evMobileApp.jpg"),
  processAutomation: require("./processAutomation.jpg"),
  serConsRight: require("./serConsRight.png"),
  serConsLeft: require("./serConsLeft.png"),
  complicated: require("./complicated.png"),
  interpersonalSkills: require("./interpersonalSkills.png"),
  statistical: require("./statistical.png"),
  rating: require("./rating.png"),
  skill: require("./skill.png"),
  analyticalSkill: require("./analyticalSkill.png"),
  designThinking: require("./designThinking.png"),
  leadership: require("./leadership.png"),
  manpowerImg2: require("./manpowerImg2.png"),
  //solar
  solarGal0: require("./solarGal0.png"),
  solarGal1: require("./solarGal1.png"),
  solarGal2: require("./solarGal2.png"),
  solarGal3: require("./solarGal3.png"),
  solarImage1: require("./solarImage1.png"),
  solarImage2: require("./solarImage2.png"),
  solarImage3: require("./solarImage3.png"),
  solarInnerB2: require("./solarInnerB2.jpg"),
  solarPortfolio1: require("./solarPortfolio1.jpg"),
  solarPortfolio2: require("./solarPortfolio2.jpg"),
  solarPortfolio3: require("./solarPortfolio3.jpg"),
  solarWhyBg: require("./solarWhyBg.png"),
  solarInstallation: require("./solarInstallation.jpg"),
  solarConsultancy: require("./solarConsultancy.jpg"),
  solarMaintain: require("./solarMaintain.jpg"),
  rightArrow: require("./rightArrow.png"),
};
