import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../utils";

function Social() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  footerPageSections(where: { termTaxonomyId: "36" }) {
    nodes {
      footerPages(where: { orderby: { field: DATE, order: ASC } }) {
        nodes {
          allPageData {
            mainHeading
            pageLink
          }
        }
      }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        setData(
          responseData.data.footerPageSections.nodes[0].footerPages.nodes
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="icons">
      <div>
        <ul>
          {data &&
            data.map((item, index) => (
              <li key={index}>
                <a
                  href={item.allPageData.pageLink}
                  dangerouslySetInnerHTML={{
                    __html: item.allPageData.mainHeading,
                  }}
                  target="_blank"
                ></a>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default Social;
