import React, { useState, useEffect } from "react";
// import { images } from "../../../assets/images";
// import { BASE_URL } from "../../../utils";

function Footer() {
  //   const [data, setData] = useState("");
  //   useEffect(() => {
  //     const fetchData = async () => {
  //       try {
  //         const response = await fetch(BASE_URL, {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({
  //             query: `{
  //   footerPages(where: {id: 610}) {
  //     nodes {
  //       content
  //     }
  //   }
  // }
  // `,
  //           }),
  //         });
  //         const responseData = await response.json();
  //         setData(responseData.data.footerPages.nodes[0].content);
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     };
  //     fetchData();
  //   }, []);
  return (
    <div className="lastSection" data-aos="fade-up" data-aos-duration="1000">
      <div className="container-fluid lastSec">
        <div className="row py-3">
          <div className="col-lg-3 col-md-3 mt-md-0 mt-2">
            <div className="footer-logo">
              <a href="/" style={{ textDecoration: "none", color: "white" }}>
                {/* <img
                  src={images.desktopLogo}
                  alt=""
                  className="img-fluid  nav-logo"
                /> */}
                <p
                  style={{
                    fontSize: 19,
                    width: 190,
                    margin: 0,
                    fontWeight: "500",
                  }}
                >
                  Smartech Advisory
                </p>
              </a>
              <h5 className="mt-2 mt-lg-4 fw-bold text-decoration-underline">
                <a href="/about" className="text-light">
                  About Us
                </a>
              </h5>
              <p className="wrap footer-about">
                Smartech was established with an objective of providing
                sustainable energy, advanced technology, advisory and
                consultancy service and support across industries powered by
                innovation solution to our clients with value and high-value
                decision.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 mt-sm-2">
            <div className="footer-links d-flex justify-content-center">
              <h5 className="text-start fw-bold text-decoration-underline text-light">
                Division
              </h5>
              <ul className="footer-menu ">
                <li>
                  <a href="/consultancy">Consultancy</a>
                </li>
                <li>
                  <a href="/energy-advisory">Energy Advisory</a>
                </li>
                <li>
                  <a href="/information-technology">Information Technology</a>
                </li>
                <ul className="footer-menu ">
                  <a className="text-start text-decoration-underline fw-bold">
                    Infra
                  </a>
                </ul>
                <li>
                  <a href="/solar">Solar Energy</a>
                </li>
                <li>
                  <a href="/metering-solutions">Metering Solutions</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 mt-sm-2">
            <div className="footer-links d-flex justify-content-center">
              <h5 className="text-start fw-bold text-decoration-underline text-light">
                Information Technology
              </h5>
              {/* <ul className="footer-menu ">
                <a
                  className="text-start text-decoration-underline fw-bold"
                  href="/information-technology"
                >
                  Information Technology
                </a>
              </ul> */}
              <ul className="footer-menu ">
                <li>
                  <a href="/it-services">IT Services</a>
                </li>
                <li>
                  <a href="/it-consultancy">IT Consultancy</a>
                </li>
                <li>
                  <a href="/it-manpower">IT Manpower</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 mt-sm-2">
            <div className="footer-social ">
              <h5 className=" fw-bold  text-decoration-underline text-light">
                STAY IN TOUCH
              </h5>
              <p className=" pb-2">
                Call or Visit us at one of Our different locations
              </p>
              <a className="smButton2" href="/contact">
                Contact Us
                <div className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="footer-hr"></div>
        <div className="footer-bottom">
          <p className="d-flex justify-content-center">
            &copy; 2024 Smartech.Com. All rights reserved.
          </p>
        </div>
      </div>
    </div>

    // <div className="lastSection" data-aos="fade-up" data-aos-duration="1000">
    //   <div dangerouslySetInnerHTML={{ __html: data }}></div>
    // </div>
  );
}

export default Footer;
