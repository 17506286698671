import React, { Suspense, lazy } from "react";
import Social from "../components/Common/Social";
import Chatbot from "../components/Common/Chatbot";
import Header from "../components/AboutPage/Header";
import Loader from "../components/Common/Loader";
import { Helmet } from "react-helmet-async";

const AboutUs = lazy(() => import("../components/AboutPage/AboutUs"));
const Section3 = lazy(() =>
  import("../components/AboutPage/Section3/Section3")
);
// const Team = lazy(() => import("../components/AboutPage/Team"));
const Footer = lazy(() => import("../components/Common/Footer"));

function About() {
  return (
    <div
      style={{
        fontFamily: '"Montserrat", sans-serif',
        backgroundColor: "#eee",
      }}
    >
      <Helmet>
        <meta
          name="title"
          content="About: SmarTech Advisory — Innovation through future-forward Smart technology solutions."
        />

        <meta
          name="description"
          content="SmarTech Advisory — a leader in smart technology and global innovation, providing best consultancy, energy advisory, infrastructure, and IoT solutions."
        />
        <title>
          About SmarTech – Global Leaders in Renewable Energy & Smart Technology
          Solutions
        </title>
      </Helmet>
      <Header />
      <Suspense fallback={<Loader />}>
        <AboutUs />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Section3 />
      </Suspense>
      {/* <Suspense fallback={<div>Loading Team...</div>}>
        <Team />
      </Suspense> */}
      <Suspense fallback={<Loader />}>
        <Footer />
      </Suspense>
      <Social />
      <Chatbot />
    </div>
  );
}

export default About;
