import React, { Suspense, lazy } from "react";
import Header from "../components/ManpowerServices/Header";
import Social from "../components/Common/Social";
import Chatbot from "../components/Common/Chatbot";
import Loader from "../components/Common/Loader";
import { Helmet } from "react-helmet-async";

const CardsSection = lazy(() =>
  import("../components/ManpowerServices/CardsSection")
);
const TopNote = lazy(() => import("../components/ManpowerServices/TopNote"));
const Features = lazy(() => import("../components/ManpowerServices/Features"));
const EndNote = lazy(() => import("../components/ManpowerServices/EndNote"));
const Footer = lazy(() => import("../components/Common/Footer"));

function ManpowerServices() {
  return (
    <div className="wrapper" style={{ fontFamily: '"Montserrat", sans-serif' }}>
      <Helmet>
        <meta
          name="title"
          content="IT Manpower Solutions - Expert Talent for Smart Technology | SmarTech"
        />
        <meta
          name="description"
          content="SmarTech provides top-tier IT manpower solutions, offering skilled professionals in technology consulting, IoT, digital transformation, and renewable energy IT solutions."
        />
        <title>
          Expert IT Manpower – Skilled Professionals for Digital Transformation
          & Tech Growth
        </title>
      </Helmet>
      <Header />

      <div style={{ backgroundColor: "#eee" }}>
        <Suspense fallback={<Loader />}>
          <CardsSection />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <TopNote />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <Features />
        </Suspense>
      </div>

      <Suspense fallback={<div>Loading ...</div>}>
        <EndNote />
      </Suspense>

      <Suspense fallback={<div>Loading ...</div>}>
        <Footer />
      </Suspense>

      <Social />
      <Chatbot />
    </div>
  );
}

export default ManpowerServices;
