import React, { useEffect, useState } from "react";
import Navbaar from "../Common/Navbar";
import { BASE_URL } from "../../utils";
function Header() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  headersPages(where: {id:589}) {
    nodes {
      title    
      featuredImage {
            node {
              sourceUrl
            }
          }
          
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.headersPages.nodes[0]);
        setData(responseData.data.headersPages.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    // data &&
    // data.featuredImage &&
    // data.featuredImage.node && (
    <div
      className="container-fluid banner position-relative"
      style={{
        background:
          data && data.featuredImage && data.featuredImage.node
            ? `linear-gradient(290deg, #000000d1, #439a7dbc),url(${data.featuredImage.node.sourceUrl}) no-repeat`
            : "",
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      <header>
        <Navbaar />
      </header>
      <div className="conatiner about-banner ">
        <div className="row align-items-center ">
          <div className=" col-12 col-md-12  text-center bannerLeft">
            <h1>{data ? data.title : ""}</h1>
          </div>
        </div>
      </div>
      <a href="#media" className="scrollButton">
        <span></span>
        <span></span>
        <span></span>Scroll
      </a>
    </div>
  );
  // );
}

export default Header;
