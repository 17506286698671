import React from "react";
import Navbar from "../Common/Navbar";
import ParticlesBg from "./ParticlesBg";

function Header() {
  return (
    <div className="banner position-relative">
      <div className="canvas-wrapper">
        <div id="canvas">
          <ParticlesBg />
        </div>
      </div>

      <div className="header-wrapper d-flex justify-content-center align-items-center">
        <Navbar />
        <div className="text-center banner-text container ">
          <div className="heading">
            <span className="type" id="typewriter" style={{ "--n": 20 }}>
              Up to Evolve
            </span>
          </div>
          <p className="wrap mb-0 text-center">Action. Excellence. Result.</p>
        </div>
      </div>
      <div id="bottomTape">
        <div className="container-fluid ">
          <div className="row section1">
            <div className="col-12 left-col banner-text-animated">
              <h2 className="banner-text-animated m-0">
                <div className="relative-text d-flex">
                  We are your partner in reliable and resilient innovation
                </div>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <a href="#indexSec2" className="scrollButton" id="indexScrollBtn">
        <span></span>
        <span></span>
        <span></span>
        Scroll
      </a>
    </div>
  );
}

export default Header;
