import React, { Suspense, lazy } from "react";
import Header from "../components/Solar/Header";
import Social from "../components/Common/Social";
import Chatbot from "../components/Common/Chatbot";
import Loader from "../components/Common/Loader";
import { Helmet } from "react-helmet-async";

const Section1 = lazy(() => import("../components/Solar/Section1"));
const Services = lazy(() => import("../components/Solar/Services/Services"));
const WhyUs = lazy(() => import("../components/Solar/WhyUs"));
const Projects = lazy(() => import("../components/Solar/Projects/Projects"));
const Calculator = lazy(() =>
  import("../components/Solar/Calculator/Calculator")
);
const Support = lazy(() => import("../components/Common/Support/Support"));
const Footer = lazy(() => import("../components/Common/Footer"));

function Solar() {
  return (
    <div style={{ fontFamily: '"Montserrat", sans-serif' }}>
      <Helmet>
        <meta
          name="title"
          content="Infra-Solar Energy Solutions | Smart Infrastructure for Solar Energy Systems"
        />
        <meta
          name="description"
          content="Infra Solar energy solutions — innovative infrastructure for solar energy systems, ensuring seamless installation, real-time monitoring, and efficient energy management."
        />
        <title>
          Solar Energy Consulting – Best Renewable Energy Solutions for Homes &
          Businesses
        </title>
      </Helmet>
      <Header />

      <div>
        <Suspense fallback={<Loader />}>
          <Section1 />
        </Suspense>

        <Suspense fallback={<Loader />}>
          <Services />
        </Suspense>

        <Suspense fallback={<Loader />}>
          <WhyUs />
        </Suspense>

        <Suspense fallback={<Loader />}>
          <Projects />
        </Suspense>

        <Suspense fallback={<Loader />}>
          <Calculator />
        </Suspense>

        <Suspense fallback={<Loader />}>
          <Support mail="solar@smartechadvisory.com" />
        </Suspense>

        <Suspense fallback={<Loader />}>
          <Footer />
        </Suspense>

        <Social />
        <Chatbot />
      </div>
    </div>
  );
}

export default Solar;
