import React, { useState, useEffect, useRef } from "react";
import { BASE_CHATBOT_URL } from "../../utils";

function Chatbot() {
  const chatboxRef = useRef(null);
  const [showChat, setShowChat] = useState(false);
  const [outMsg, setOutMsg] = useState("");
  const [allMessages, setAllMessages] = useState([
    {
      type: "incoming",
      message: "Welcome to Smartech Advisory. How can I help you?",
    },
  ]);

  const getResponse = async () => {
    try {
      const response = await fetch(BASE_CHATBOT_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: "Smartech", msg: outMsg }),
      });

      let responseData;
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes("application/json")) {
        responseData = await response.json();
      } else {
        responseData = await response.text();
      }
      // console.log("chatbot response----------", responseData);
      setAllMessages((prevMessages) => [
        ...prevMessages,
        {
          type: "incoming",
          message:
            typeof responseData === "string"
              ? responseData
              : responseData.reply,
        },
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const sendMsg = () => {
    if (outMsg.trim() !== "") {
      setAllMessages([...allMessages, { type: "outgoing", message: outMsg }]);
      setOutMsg("");
      getResponse();
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMsg();
    }
  };

  useEffect(() => {
    if (chatboxRef.current) {
      chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
    }
  }, [allMessages]);

  return (
    <div>
      <button
        className="chatbot-toggler shadow m-0"
        onClick={() => setShowChat(!showChat)}
      >
        <span>
          <i className="fa-solid fa-robot"></i>
        </span>
        <span className="position-absolute tool-tip shadow">Ask Me</span>
      </button>
      {showChat && (
        <div className="chatbot shadow" id="chatbot">
          <header className="d-flex justify-content-between align-items-center">
            <h2>Chatbot</h2>
            <span>
              <i
                className="ri-close-line"
                onClick={() => setShowChat(!showChat)}
              ></i>
            </span>
          </header>
          <ul className="chatbox" ref={chatboxRef}>
            {allMessages.map((item, id) => (
              <li key={id} className={`chat ${item.type}`}>
                {item.type === "incoming" && (
                  <span>
                    <i className="ri-robot-2-line"></i>
                  </span>
                )}
                <p className="banner-text-animated">{item.message}</p>
              </li>
            ))}
          </ul>
          <div className="chat-input">
            <textarea
              id="message-input"
              placeholder="Enter a message..."
              required
              value={outMsg}
              onChange={(e) => setOutMsg(e.target.value)}
              onKeyDown={handleKeyDown}
            ></textarea>
            <span id="send-btn" onClick={sendMsg}>
              <i className="ri-send-plane-2-line"></i>
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default Chatbot;
