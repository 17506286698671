import React, { Suspense, lazy } from "react";
import Header from "../components/ITServices/Header";
import Social from "../components/Common/Social";
import Chatbot from "../components/Common/Chatbot";
import Loader from "../components/Common/Loader";
import { Helmet } from "react-helmet-async";

const Services = lazy(() => import("../components/ITServices/Services"));
const EndNote = lazy(() => import("../components/ITServices/EndNote"));
const Footer = lazy(() => import("../components/Common/Footer"));

function ITServices() {
  return (
    <div
      className="container-fluid p-0"
      style={{ fontFamily: '"Montserrat", sans-serif' }}
    >
      <Helmet>
        <meta
          name="title"
          content="IT Services - Smart Technology & Digital Transformation | SmarTech"
        />
        <meta
          name="description"
          content="SmarTech offers cutting-edge IT services, including smart technology solutions, IoT integration, and digital transformation strategies for businesses and governments."
        />
        <title>
          Comprehensive IT Services – Smart & Scalable Solutions for Businesses
        </title>
      </Helmet>

      <Header />
      <Suspense fallback={<Loader />}>
        <Services />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <EndNote />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Footer />
      </Suspense>
      <Social />
      <Chatbot />
    </div>
  );
}

export default ITServices;
