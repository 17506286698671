import React, { Suspense, lazy } from "react";
import Header from "../components/Technology/Header";
import Social from "../components/Common/Social";
import Chatbot from "../components/Common/Chatbot";
import Loader from "../components/Common/Loader";
import { Helmet } from "react-helmet-async";

const WhyUs = lazy(() => import("../components/Technology/WhyUs"));
const Services = lazy(() => import("../components/Technology/Services"));
const TechStack = lazy(() => import("../components/Technology/TechStack"));
const Support = lazy(() => import("../components/Common/Support/Support"));
const Footer = lazy(() => import("../components/Common/Footer"));

function Technology() {
  return (
    <div style={{ fontFamily: '"Montserrat", sans-serif' }}>
      <Helmet>
        <meta
          name="title"
          content="IT & Smart Energy Solutions by SmarTech — Digital Transformation Experts"
        />
        <meta
          name="description"
          content="Transform with innovative technology: Choose SmarTech’s IT solutions, adept with AI integration, cloud services, data analytics, and cybersecurity strategies for a smarter, connected world."
        />
        <title>
          Advanced IT & Digital Transformation – Smart Technology for Energy &
          Business Growth
        </title>
      </Helmet>
      <Header />
      <Suspense fallback={<Loader />}>
        <WhyUs />
      </Suspense>

      <Suspense fallback={<Loader />}>
        <Services />
      </Suspense>

      <Suspense fallback={<Loader />}>
        <TechStack />
      </Suspense>

      <Suspense fallback={<Loader />}>
        <Support mail="sales@smartechadvisory.com" />
      </Suspense>

      <Suspense fallback={<Loader />}>
        <Footer />
      </Suspense>

      <Social />
      <Chatbot />
    </div>
  );
}

export default Technology;
