import React, { Suspense, lazy } from "react";
import Header from "../components/MeteringSolutions/Header";
import Social from "../components/Common/Social";
import Chatbot from "../components/Common/Chatbot";
import Loader from "../components/Common/Loader";
import { Helmet } from "react-helmet-async";

const WhyUs = lazy(() => import("../components/MeteringSolutions/WhyUs"));
const EMD = lazy(() => import("../components/MeteringSolutions/EMD"));
const Services = lazy(() => import("../components/MeteringSolutions/Services"));
const Footer = lazy(() => import("../components/Common/Footer"));

function MeteringSolutions() {
  return (
    <div style={{ fontFamily: '"Montserrat", sans-serif' }}>
      <Helmet>
        <meta
          name="title"
          content="Infra-Metering Solutions | Advanced Energy Tracking and Optimization"
        />
        <meta
          name="description"
          content="Infra-Metering solutions — advanced metering technology designed to track, optimize, and manage energy consumption and generation with precision."
        />
        <title>
          Smart Metering Solutions – Energy Efficiency & Accurate Power
          Monitoring
        </title>
      </Helmet>
      <Header />
      <Suspense fallback={<Loader />}>
        <WhyUs />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <EMD />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Services />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Footer />
      </Suspense>
      <Social />
      <Chatbot />
    </div>
  );
}

export default MeteringSolutions;
