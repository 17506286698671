import React, { useState, useEffect } from "react";
import Navbar from "../Common/Navbar";
import { BASE_URL } from "../../utils";

function Header() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  headersPages(where: {id:593}) {
    nodes {
      title    
      featuredImage {
            node {
              sourceUrl
            }
          }
          
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.headersPages.nodes[0]);
        setData(responseData.data.headersPages.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    // data && (
    <div
      className="container-fluid banner position-relative contact-bg "
      style={{
        backgroundImage:
          data && data.featuredImage && data.featuredImage.node
            ? `url(${data.featuredImage.node.sourceUrl})`
            : "",
      }}
    >
      <header>
        <Navbar />
      </header>
      <div className="conatiner about-banner ">
        <div className="row align-items-center ">
          <div className=" col-12 col-md-12  text-center bannerLeft">
            {/* <h1>{data.title}</h1> */}
            <h1>{data ? data.title : ""}</h1>
          </div>
        </div>
      </div>
      <a href="#contact" className="scrollButton">
        <span></span>
        <span></span>
        <span></span>Scroll
      </a>
    </div>
  );
  // );
}

export default Header;
