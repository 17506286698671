import React from "react";

import Footer from "../components/Common/Footer";
import Social from "../components/Common/Social";
import Chatbot from "../components/Common/Chatbot";

import Header from "../components/Media/Header";
import UnderDevelopement from "../components/Common/UnderDevelopement";
import { Helmet } from "react-helmet-async";

function Media() {
  return (
    <div style={{ fontFamily: '"Montserrat", sans-serif' }}>
      <Helmet>
        <title>
          SmarTech News & Insights – The Latest in Renewable Energy & Smart
          Technology
        </title>
      </Helmet>
      <Header />
      {/* <Tabs /> */}
      <UnderDevelopement />
      <Footer />
      <Social />
      <Chatbot />
    </div>
  );
}

export default Media;
