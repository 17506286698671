import React, { useEffect, useState } from "react";
import Navbar from "../Common/Navbar";
import tech from "../../assets/technology.svg";
import { BASE_URL } from "../../utils";
function Header() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  headersPages(where: {id:584}) {
    nodes {
      content
           allPageData {
            mainHeading
            subHeading
            
          }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.headersPages.nodes[0]);
        setData(responseData.data.headersPages.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    // data &&
    // data.content &&
    // data.allPageData && (
    <div id="background">
      <Navbar />
      <div className="top">
        <h1>
          {data && data.allPageData ? data.allPageData.mainHeading : ""} <br />
          {data && data.allPageData ? data.allPageData.subHeading : ""}
        </h1>
      </div>
      <div className="banner-left">
        <img src={tech} alt="" />
        <div className="connecting-dots"></div>
      </div>
      <div className="banner-right">
        <div className="design">
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: data ? data.content : "" }}
          ></div>
        </div>
      </div>
      <a
        href="/contact"
        className="contactUsButton d-flex justify-content-center"
      >
        <span className="smButton2">
          Contact Us
          <div className="icon">
            <svg
              height="24"
              width="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path
                d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </span>
      </a>
      <a href="#techStack" className="scrollButton">
        <span></span>
        <span></span>
        <span></span>Scroll
      </a>
    </div>
  );
  // );
}

export default Header;
