import React, { Suspense, lazy } from "react";
import Header from "../components/ConsultancyServices/Header";
import Social from "../components/Common/Social";
import Chatbot from "../components/Common/Chatbot";
import Loader from "../components/Common/Loader";
import { Helmet } from "react-helmet-async";

const TopNote = lazy(() => import("../components/ConsultancyServices/TopNote"));
const Features = lazy(() =>
  import("../components/ConsultancyServices/Features")
);
const EndNote = lazy(() => import("../components/ConsultancyServices/EndNote"));
const Footer = lazy(() => import("../components/Common/Footer"));

function ConsultancyServices() {
  return (
    <div style={{ fontFamily: '"Montserrat", sans-serif' }}>
      <Helmet>
        <meta
          name="title"
          content="IT Consultancy - Expert Advisory for Digital Transformation | SmarTech"
        />
        <meta
          name="description"
          content="SmarTech provides IT consultancy services, specializing in technology advisory, smart city solutions, and energy-efficient digital strategies for businesses, PSUs, and governments."
        />
        <title>
          Technology & Business Advisory – IT Consulting for Sustainable Growth
        </title>
      </Helmet>
      <Header />

      <div style={{ backgroundColor: "#eee" }}>
        <Suspense fallback={<Loader />}>
          <TopNote />
        </Suspense>

        <Suspense fallback={<Loader />}>
          <Features />
        </Suspense>
      </div>

      <Suspense fallback={<Loader />}>
        <EndNote />
      </Suspense>

      <Suspense fallback={<Loader />}>
        <Footer />
      </Suspense>

      <Social />
      <Chatbot />
    </div>
  );
}

export default ConsultancyServices;
