import React from "react";
import { useEffect, useState } from "react";
import Aos from "aos";
import { BASE_URL } from "./utils";
import "aos/dist/aos.css";
import Favicon from "react-favicon";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Media from "./pages/Media";
import Technology from "./pages/Tehnology";
import Consultancy from "./pages/Consultancy";
import EnergyAdvisory from "./pages/EnergyAdvisory";
import MeteringSolutions from "./pages/MeteringSolutions";
// import Career from "./pages/Career";
import ITServices from "./pages/ITServices";
import ConsultancyServices from "./pages/ConsultancyServices";
import ManpowerServices from "./pages/ManpowerServices";
import Solar from "./pages/Solar";
import NotFound from "./pages/NotFound";

import { Helmet } from "react-helmet-async";
function App() {
  const [icon, setIcon] = useState("");
  const fetchData = async () => {
    try {
      const response = await fetch(BASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `{
  headersPages(where: {id:627}) {
    nodes {
     featuredImage {
            node {
              sourceUrl
            }
          }
    }
  }
}`,
        }),
      });
      const responseData = await response.json();
      setIcon(
        responseData.data.headersPages.nodes[0].featuredImage.node.sourceUrl
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    Aos.init();
    fetchData();
  }, []);

  let pageUrl = window.location.href;
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={pageUrl.endsWith("/") ? pageUrl.slice(0, -1) : pageUrl}
        />
      </Helmet>
      <div className="App">
        <Favicon url={icon} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/consultancy" element={<Consultancy />} />
          <Route path="/energy-advisory" element={<EnergyAdvisory />} />
          <Route path="/information-technology" element={<Technology />} />
          <Route path="/solar" element={<Solar />} />
          <Route path="/metering-solutions" element={<MeteringSolutions />} />
          <Route path="/it-consultancy" element={<ConsultancyServices />} />
          <Route path="/it-manpower" element={<ManpowerServices />} />
          <Route path="/it-services" element={<ITServices />} />
          {/* <Route path="/career" element={<Career />} /> */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/media" element={<Media />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
