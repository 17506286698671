import React, { Suspense, lazy } from "react";
import Social from "../components/Common/Social";
import Chatbot from "../components/Common/Chatbot";
import Header from "../components/Contact/Header";
import Loader from "../components/Common/Loader";
import { Helmet } from "react-helmet-async";

const ContactUs = lazy(() => import("../components/Contact/Section/ContactUs"));
const Footer = lazy(() => import("../components/Common/Footer"));

function Contact() {
  return (
    <div
      className="container-fluid p-0"
      style={{ fontFamily: '"Montserrat", sans-serif' }}
    >
      <Helmet>
        <title>
          Get in Touch with SmarTech – Partner with Experts in Renewable Energy
          & Smart Technology
        </title>
      </Helmet>
      <Header />
      <Suspense fallback={<Loader />}>
        <ContactUs />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Footer />
      </Suspense>
      <Social />
      <Chatbot />
    </div>
  );
}

export default Contact;
