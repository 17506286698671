import React, { Suspense, lazy } from "react";
import Social from "../components/Common/Social";
import Chatbot from "../components/Common/Chatbot";
import Header from "../components/EnergyAdvisory/Header";
import Loader from "../components/Common/Loader";
import { Helmet } from "react-helmet-async";

const OurBelieve = lazy(() =>
  import("../components/EnergyAdvisory/OurBelieve")
);
const Services = lazy(() => import("../components/EnergyAdvisory/Services"));
const Support = lazy(() => import("../components/Common/Support/Support"));
const Footer = lazy(() => import("../components/Common/Footer"));

function EnergyAdvisory() {
  return (
    <div
      style={{
        fontFamily: '"Montserrat", sans-serif',
        backgroundColor: "#eee",
      }}
    >
      <Helmet>
        <meta
          name="title"
          content="Energy Advisory by SmarTech — Sustainable Energy Solutions"
        />

        <meta
          name="description"
          content="Make smarter energy decisions today! Choose SmarTech’s energy advisory services for renewable energy strategies, sustainability planning, and efficient power management."
        />
        <title>
          Energy Advisory – Strategic Consulting for Renewable Energy & Energy
          Efficiency Solutions
        </title>
      </Helmet>
      <Header />
      <Suspense fallback={<Loader />}>
        <OurBelieve />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Services />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Support mail="sales@smartechadvisory.com" id="contactFormSection" />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Footer />
      </Suspense>
      <Social />
      <Chatbot />
    </div>
  );
}

export default EnergyAdvisory;
