import React, { useState, useEffect } from "react";
import Navbar from "../Common/Navbar";
import { BASE_URL } from "../../utils";

function Header() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  headersPages(where: {id:585}) {
    nodes {
      title
      content
      featuredImage {
            node {
              sourceUrl
            }
          }
           allPageData {
            mainHeading
            subHeading
            pageLink
            
          }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        // console.log(responseData.data.headersPages.nodes[0]);
        setData(responseData.data.headersPages.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    // data.allPageData && (
    <div
      // className="consultancy-banner"
      style={{
        height: "100vh",
        width: "100%",
        position: "relative",
        backgroundImage:
          data && data.featuredImage && data.featuredImage.node
            ? `url(${data.featuredImage.node.sourceUrl})`
            : "",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Navbar />
      <div className="content-wrapper container-fluid p-0">
        <div className=" row m-0">
          <div className="col-sm-12 col-md-6 leftContainer">
            <div className="bannerText">
              <h1>{data ? data.title : ""}</h1>
              <h3>
                {data && data.allPageData ? data.allPageData.mainHeading : ""}
              </h3>
              <p>
                {data && data.allPageData ? data.allPageData.subHeading : ""}
              </p>
              <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
              <a
                href="/contact"
                className="contactButton d-flex justify-content-start mt-2"
              >
                <span className="smButton2">
                  Contact Us
                  <div className="icon">
                    <svg
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                </span>
              </a>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 rightContainer"></div>
          <a href="#tileContainer" className="scrollButton">
            <span></span>
            <span></span>
            <span></span>Scroll
          </a>
        </div>
      </div>
    </div>
  );
  // );
}

export default Header;
