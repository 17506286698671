import React from "react";
import { images } from "../../assets/images";

function UnderDevelopement() {
  return (
    <div
      className="container text-start my-md-5 my-3"
      data-aos="fade-up"
      data-aos-duration="1000"
      id="media"
    >
      <div className="row">
        <div className="col-lg-12 col-12 text-center">
          <img src={images.careerSec} alt="" className="img-fluid" />
        </div>
        <div className="col-lg-12 col-12 text-center">
          <h2 data-aos="fade-up" data-aos-duration="1000">
            Page Under Development
          </h2>
          <div className="d-flex justify-content-center mt-3">
            <a className="smButton" href="./index.html">
              Go back
              <div className="icon">
                <svg
                  height="24"
                  width="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnderDevelopement;
