import React, { useEffect, useState } from "react";
import Section1 from "./Section1";
import Navbar from "../Common/Navbar";
import { BASE_URL } from "../../utils";

function Header() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  headersPages(where: {id:568}) {
    nodes {
    content
     featuredImage {
            node {
              sourceUrl
            }
          }
           allPageData {
            mainHeading
            subHeading
          }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        setData(responseData.data.headersPages.nodes[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    // data &&
    // data.featuredImage &&
    // data.featuredImage.node &&
    // data.allPageData && (
    <div
      className="container-fluid banner consult_banner b_c_fix position-relative"
      style={{
        background:
          data && data.featuredImage && data.featuredImage.node
            ? `linear-gradient(290deg, #000000d1, #439a7dbc),url(${data.featuredImage.node.sourceUrl}) no-repeat`
            : "",
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      <Navbar />
      <Section1 headings={data.allPageData ? data.allPageData : ""} />
    </div>
  );
  // );
}

export default Header;
