import React, { useEffect, useState } from "react";
import Navbar from "../Common/Navbar";
import { BASE_URL } from "../../utils";

function Header() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `{
  headersPages(where: {id:582}) {
    nodes {
           allPageData {
            mainHeading
            pageLink
            
          }
    }
  }
}`,
          }),
        });
        const responseData = await response.json();
        setData(responseData.data.headersPages.nodes[0].allPageData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    // data && (
    <div className="container-fluid position-relative p-0">
      <div className="position-relative">
        <video
          src={data ? data.pageLink : ""}
          width="100%"
          height="726"
          // controls="controls"
          autoPlay={true}
          loop={true}
          muted={true}
          id="myVideo"
        />

        <div className="row banner position-absolute top-0 w-100 tec-vido-banner m-0">
          <div className="col-lg-12 p-0">
            <div className="header-wrapper d-flex justify-content-center align-items-center">
              <Navbar />
              <h1 className="tech_banner_head">
                {" "}
                {data ? data.mainHeading : ""}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <a href="#mySwiper-proj" className="scrollButton">
        <span></span>
        <span></span>
        <span></span>Scroll
      </a>
    </div>
    // )
  );
}

export default Header;
