import React, { Suspense, lazy } from "react";
import Social from "../components/Common/Social";
import Header from "../components/Consultancy/Header";
import Chatbot from "../components/Common/Chatbot";
import Loader from "../components/Common/Loader";
import { Helmet } from "react-helmet-async";

const TopNote = lazy(() => import("../components/Consultancy/TopNote"));
const Services = lazy(() => import("../components/Consultancy/Services"));
const Support = lazy(() => import("../components/Common/Support/Support"));
const Footer = lazy(() => import("../components/Common/Footer"));

function Consultancy() {
  return (
    <div style={{ fontFamily: '"Montserrat", sans-serif' }}>
      <Helmet>
        <meta
          name="title"
          content="Consultancy Solutions by SmarTech Advisory — Strategy & Innovation"
        />

        <meta
          name="description"
          content="Start your sustainable journey with SmarTech Advisory’s consultancy for strategic insights, innovative business solutions, and expert guidance to drive digital transformation."
        />
        <title>
          Renewable Energy & Smart Technology Consulting – Expert Advisory for
          Businesses & Public Sector
        </title>
      </Helmet>
      <Header />
      <Suspense fallback={<Loader />}>
        <TopNote />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Services />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Support mail="sales@smartechadvisory.com" id="contactUsForm" />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Footer />
      </Suspense>
      <Social />
      <Chatbot />
    </div>
  );
}

export default Consultancy;
